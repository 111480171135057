import { gql } from '@apollo/client';

import redirect from 'lib/redirect';
import ProgramPage from 'components/program-page/ProgramPage';
import ProgramPageLayout from 'components/layouts/ProgramPageLayout';

const GET_SLUG = gql`
  query GetSlug($id: String!) {
    findPrograms(id: $id) {
      id
      slug
    }
  }
`;

const ProgramPageRoute = () => <ProgramPage />;

ProgramPageRoute.getLayout = (page, router) => (
  <ProgramPageLayout id={router.query.id}>{page}</ProgramPageLayout>
);

// Redirect to the vanity URL if it exists
ProgramPageRoute.getInitialProps = async function getInitialProps({ query, apolloClient, res }) {
  const response = await apolloClient.query({
    query: GET_SLUG,
    variables: { id: query.id },
  });

  const program = response.data?.findPrograms[0];

  if (!program) {
    res.statusCode = 404;
    return {};
  }

  // query.slug will already be set if rendering from the slug route
  if (program.slug && !query.slug) redirect(res, { url: `/${program.slug}` });
  return { program };
};

export default ProgramPageRoute;
