// Vanity Root URLs: Campaign & Program main pages
import PropTypes from 'prop-types';

import { fetchSlug } from 'lib/vanityUrlHelpers';
import CampaignRoute from '../c/[id]';
import ProgramRoute from '../pr/[id]';
import NotFoundPage from '../404';

const ROUTE_MAP = {
  Campaign: CampaignRoute,
  Program: ProgramRoute,
};

const VanityRootRoute = ({ vanityRecord, ...props }) => {
  if (!vanityRecord) return <NotFoundPage />;
  const RouteComponent = ROUTE_MAP[vanityRecord.type];
  return RouteComponent ? <RouteComponent {...props} /> : null;
};

VanityRootRoute.propTypes = {
  vanityRecord: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
};

VanityRootRoute.getLayout = (page, router, pageProps) => {
  if (!pageProps.vanityRecord) return page;
  const RouteComponent = ROUTE_MAP[pageProps.vanityRecord.type];
  return RouteComponent?.getLayout?.(page, router, pageProps) ?? page;
};

VanityRootRoute.getInitialProps = async (ctx) => {
  const vanityRecord = await fetchSlug({ key: ctx.query.slug, scope: 'root' });
  if (!vanityRecord) {
    ctx.res.statusCode = 404;
    return {};
  }

  const RouteComponent = ROUTE_MAP[vanityRecord.type];
  if (!RouteComponent) throw new Error('Unknown vanity URL type');

  ctx.query.id = vanityRecord.objectId;
  const superProps = await RouteComponent.getInitialProps?.(ctx);
  return { vanityRecord, ...superProps };
};

export default VanityRootRoute;
