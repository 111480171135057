import { gql } from '@apollo/client';
import createApolloClient from '../createApolloClient';

// eslint-disable-next-line import/prefer-default-export
export async function fetchSlug(variables) {
  const client = createApolloClient({});

  const { data } = await client.query({
    query: gql`
      query GetSlug($key: String!, $scope: SlugScopeEnum!, $parentId: String) {
        getSlug(key: $key, scope: $scope, parentId: $parentId) {
          key
          type
          objectId
        }
      }
    `,
    variables,
  });

  return data.getSlug;
}
